<template>
  <div class="school-way">
    <div class="school-way__inner v2-container-small">
      <div class="school-way__sticker" />

      <h2 v-html="title" />

      <div class="school-way__tabs tabs">
        <div class="tabs__head">
          <button
            v-for="(item, index) in wayList"
            :key="index"
            :class="['m-btn', 'tabs__btn', { 'tabs__btn--active': activeTab === index }]"
            :style="activeTab === index ? { backgroundColor: item.tabColor } : {}"
            @click="activeTab = index;"
          >
            <span class="tabs__age title-secondary">
              {{ item.classes }}
            </span>
            класс
          </button>
        </div>

        <div
          class="school-way__tariffs"
          @scroll="onTarrifsScroll"
        >
          <div
            v-for="tariff in tariffsList"
            :key="tariff.type"
            class="school-way__tariff tariff"
          >
            <img
              v-if="activeWay.stickers[tariff.type]"
              loading="lazy"
              :src="activeWay.stickers[tariff.type]"
              alt="top"
              class="tariff__sticker"
            >

            <h3 class="title-tertiary">
              {{ tariff.title }}
            </h3>

            <div class="tariff__head">
              <p
                class="tariff__description"
                v-html="tariff.description"
              />

              <div class="tariff__chips">
                <div
                  v-for="(chip, idx) in tariff.chips"
                  :key="idx"
                  v-html="chip"
                />
              </div>
            </div>

            <h4
              v-if="activeWay.discountPrices[tariff.type]"
              class="tariff_price tariff_price--discount"
            >
              <span
                v-if="activeWay.classes === '8-9'"
                class="tariff_price__old"
              >
                Обучение в 8 классе
              </span>
              <span
                v-else-if="activeWay.classes === '10-11' && tariff.type === 'comfort'"
                class="tariff_price__old"
              >
                Обучение в 10 классе
              </span>
              <span
                v-else
                class="tariff_price__old"
              >
                <span>{{ activeWay.prices[tariff.type] }}</span>
                ₽/год
              </span>
              <!-- eslint-disable max-len vue/max-len -->
              <span
                class="tariff_price__price"
                :style="{ color: activeWay.priceColor }"
              >{{ activeWay.discountPrices[tariff.type] }}</span>
              <!-- eslint-enable -->
              <span class="tariff_price__unit">
                {{ forCom ? '$/мес' : '₽/мес' }}
              </span>
              <span
                v-if="activeWay.classes === '8-9' && !forCom"
                class="tariff_price__installment"
              >
                {{ activeWay.prices[tariff.type] }}
              </span>
              <span
                v-if="activeWay.classes === '10-11' && tariff.type === 'comfort' && !forCom"
                class="tariff_price__installment"
              >
                в 11 классе 12 667 ₽/мес
              </span>
              <span
                v-if="!(activeWay.classes === '8-9' && !forCom) && !(activeWay.classes === '10-11' && tariff.type === 'comfort' && !forCom)"
                class="tariff_price__installment"
              >
                можно в рассрочку
              </span>
            </h4>

            <h4
              v-else
              class="tariff_price"
            >
              <span class="tariff_price__from">от</span>
              <span
                class="tariff_price__price"
                :style="{ color: activeWay.priceColor }"
              >{{ activeWay.prices[tariff.type] }}</span>
              <span class="tariff_price__unit">
                {{ forCom ? '$/мес' : '₽/мес' }}
              </span>
              <span
                v-if="activeWay.installmentPrice"
                class="tariff_price__installment"
              >
                {{ cenyPage ? 'можно' : 'цена' }} в рассрочку
              </span>
            </h4>

            <ul>
              <template v-for="(item, idx) in tariff.advantages">
                <li
                  v-if="item.show"
                  :key="idx"
                  :class="['text-primary', { 'bold': item.bold }]"
                  v-html="item.text"
                />
              </template>
            </ul>

            <button
              :class="['m-btn', { 'tariff__btn-filled': tariff.type === 'premium' }]"
              :style="{ '--clr': activeWay.fillButtonColor }"
              @click="toggleLead2Modal"
            >
              Подробнее
            </button>
          </div>
        </div>
      </div>
    </div>

    <school-way-banners />

    <school-education-forms
      v-if="onlineSchoolPage || classPage || attestaciiPage || vecherneePage || semeinoePage"
      class="school-way__education"
    />

    <packages-list
      title="Чтобы учиться было легче, у&nbsp;нас еще есть:"
      hide-tabs
      :data-variable="packagesListVariable"
      :default-active="activeTab"
    />

    <img
      id="school-way__img-star"
      loading="lazy"
      :src="`/v2/school/way/star-${activeTab}.webp`"
      alt="star"
      class="school-way__img-star"
    >
    <img
      id="school-way__img-circle-star"
      loading="lazy"
      src="/v2/school/way/circle-star.webp"
      alt="star"
      class="school-way__img-circle-star"
    >
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapActions, mapGetters } from 'vuex';

import PackagesList from '@/components/v2/common/PackagesList/PackagesList.vue';
import SchoolEducationForms from '@/components/v2/school/SchoolEducationForms.vue';
import SchoolWayBanners from './ceny/SchoolWayBanners.vue';

export default {
  name: 'SchoolWay',
  components: {
    SchoolWayBanners,
    PackagesList,
    SchoolEducationForms,
  },
  props: {
    forCom: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    activeTab: 0,
  }),

  computed: {
    ...mapGetters({
      isLaptop: 'isLaptop',
      isMobile: 'isMobile',
    }),

    wayList() {
      /* eslint-disable no-nested-ternary */
      return [
        {
          classes: '5-7',
          tabColor: '#FBD277',
          fillButtonColor: '#FF7233',
          priceColor: '#E8553E',
          prices: {
            lite: this.forCom ? '20' : '4 750',
            external: this.forCom ? '20' : '22 000',
            comfort: this.forCom ? '100' : '95 000',
            premium: this.forCom ? '242' : '237 600',
          },
          discountPrices: {
            external: this.cenyPage ? '1 833' : null,
            comfort: this.cenyPage ? '7 920' : null,
            premium: this.cenyPage ? '19 800' : null,
          },
          stickers: {
            lite: null,
            external: null,
            comfort: null,
            premium: this.forCom ? '/v2/school/way/top-0.webp' : '/v2/global/black-friday/top-sale-violet.svg',
          },
          installmentPrice: !this.forCom,
        },
        {
          classes: '8-9',
          tabColor: '#B4DF80',
          fillButtonColor: '#A8FF53',
          priceColor: '#89C053',
          prices: {
            lite: this.forCom ? '20' : '4 750',
            external: this.forCom ? '20' : 'в 9 классе 2 250 ₽/мес',
            comfort: this.forCom ? '100' : 'в 9 классе 11 085 ₽/мес',
            premium: this.forCom ? '242' : 'в 9 классе 21 390 ₽/мес',
          },
          discountPrices: {
            external: this.cenyPage ? '1 833' : null,
            comfort: this.cenyPage ? '7 920' : null,
            premium: this.cenyPage ? '19 800' : null,
          },
          stickers: {
            lite: null,
            external: null,
            comfort: null,
            premium: this.forCom ? '/v2/school/way/top-1.webp' : '/v2/global/black-friday/top-sale-blue.svg',
          },
          installmentPrice: !this.forCom,
        },
        {
          classes: '10-11',
          tabColor: '#9FE3FF',
          fillButtonColor: '#41E8FF',
          priceColor: '#3AADD9',
          prices: {
            lite: this.forCom ? '20' : '4 750',
            external: this.forCom ? '20' : '32 000',
            comfort: this.forCom ? '141' : this.cenyPage ? '15 840' : '11 090',
            premium: this.forCom ? '251' : '256 000',
          },
          discountPrices: {
            external: this.cenyPage ? '2 667' : null,
            comfort: this.cenyPage ? '11 090' : null,
            premium: this.cenyPage ? '21 390' : null,
          },
          stickers: {
            lite: null,
            external: null,
            comfort: null,
            premium: this.forCom ? '/v2/school/way/top-2.webp' : '/v2/global/black-friday/top-sale-pink.svg',
          },
          installmentPrice: !this.forCom,
        },
      ];
      /* eslint-enable */
    },

    activeWay() {
      return this.wayList[this.activeTab];
    },

    tariffs() {
      // const lite = {
      //   type: 'lite',
      //   title: 'Школа «Лайт»',
      //   chips: ['вебинары и записи', 'без прикрепления'],
      //   // eslint-disable-next-line max-len,vue/max-len
      // eslint-disable-next-line max-len,vue/max-len
      //   description: 'Вебинары и&nbsp;записи по&nbsp;всем школьным темам, для тех, кто хочет подтянуть знания по&nbsp;конкретному предмету не&nbsp;уходя из&nbsp;своей школы',
      //   advantages: [
      //     { text: 'К&nbsp;каждому уроку: <br> конспект, тренажеры, домашка', show: true },
      //     { text: 'Классный руководитель поможет организовать обучение', show: true },
      //     { text: 'Наставник ответит на&nbsp;вопросы по&nbsp;предмету и&nbsp;домашке', show: true },
      //     { text: 'Подготовка к&nbsp;ОГЭ по&nbsp;русскому языку и&nbsp;математике', show: this.activeTab === 1 },
      //     { text: 'Подготовка к&nbsp;ЕГЭ по&nbsp;русскому языку и&nbsp;математике', show: this.activeTab === 2 },
      //     { text: 'Профильные мастер-классы с&nbsp;экспертами', show: true },
      //   ],
      // };

      const external = {
        type: 'external',
        title: 'ШКОЛА «ЭКСТЕРНАТ»',
        chips: ['онлайн-аттестация', 'записи уроков', 'с прикреплением'],
        description: 'Для детей и взрослых. Поможем получить аттестат в два раза быстрее и не выходя из дома',
        advantages: [
          { text: 'Выдаем аттестат гос. образца', show: true },
          { text: 'К каждому уроку: <br> тренажеры, домашка', show: true },
          { text: 'Контрольные, практические, лабораторные', show: true },
          { text: 'Классный руководитель поможет организовать обучение', show: true },
          { text: 'Наставник ответит на вопросы по предмету и домашке', show: true },
        ],
      };

      return [
        external,
        {
          type: 'comfort',
          title: 'Школа «Комфорт»',
          chips: ['вебинары и записи', 'с прикреплением', 'онлайн-аттестация'],
          // eslint-disable-next-line max-len,vue/max-len
          description: 'Для тех, кто ценит свое время, приоритеты и&nbsp;хочет успешно сдать аттестацию&nbsp;&mdash; полноценное школьное обучение',
          advantages: [
            { text: 'Выдаем аттестат гос. образца', show: true },
            { text: 'К&nbsp;каждому уроку: <br> конспект, тренажеры, домашка', show: true },
            { text: 'Контрольные, практические, лабораторные', show: true },
            { text: 'Классный руководитель поможет организовать обучение', show: true },
            { text: 'Наставник ответит на&nbsp;вопросы по&nbsp;предмету и&nbsp;домашке', show: true },
            { text: '10+&nbsp;кружков на&nbsp;выбор', show: true },
            { text: 'Подготовка к&nbsp;ОГЭ по&nbsp;русскому языку и&nbsp;математике', show: this.activeTab === 1 },
            { text: 'Подготовка к&nbsp;ЕГЭ по&nbsp;русскому языку и&nbsp;математике', show: this.activeTab === 2 },
            { text: 'Профильные мастер-классы с&nbsp;экспертами', show: true },
          ],
        },
        {
          type: 'premium',
          title: 'Школа «Премиум»',
          chips: [
            'онлайн <span class="no-laptop">в мини-группе</span>',
            'до 25 человек',
            'с/без прикрепления',
            'онлайн-аттестация',
          ],
          // eslint-disable-next-line max-len,vue/max-len
          description: 'Максимальное вовлечение и&nbsp;персонализированный подход для выдающихся результатов в&nbsp;учебе и&nbsp;развития личности ребёнка',
          advantages: [
            { text: 'Выдаем аттестат гос. образца', show: true },
            { text: 'К&nbsp;каждому уроку: <br> конспект, тренажеры, домашка', show: true },
            { text: 'Контрольные, практические, лабораторные', show: true },
            { text: 'Персональный тьютор и&nbsp;индивидуальный образовательный маршрут', show: true, bold: true },
            { text: 'Наставник ответит на&nbsp;вопросы по&nbsp;предмету и&nbsp;домашке', show: true },
            { text: '10+&nbsp;кружков на&nbsp;выбор', show: true },
            { text: 'Подготовка к&nbsp;ОГЭ по&nbsp;русскому языку и&nbsp;математике', show: this.activeTab === 1 },
            { text: 'Подготовка к&nbsp;ЕГЭ по&nbsp;русскому языку и&nbsp;математике', show: this.activeTab === 2 },
            { text: 'Профильные мастер-классы с&nbsp;экспертами', show: true },
            { text: 'Профориентация, психолог', show: true, bold: true },
            { text: 'Курс мягких навыков', show: true, bold: true },
            { text: 'Командные проекты', show: true, bold: true },
          ],
        },
      ];
    },
    tariffsList() {
      if (this.isLaptop && !this.cenyPage) {
        return [...this.tariffs].reverse();
      }
      return this.tariffs;
    },

    cenyPage() {
      // return this.$route.name === 'online-school-ceny' || this.$route.name === 'online-school';
      return true;
    },
    onlineSchoolPage() {
      return this.$route.name === 'online-school';
    },
    classPage() {
      return Boolean(this.$route.name.match(/online-school-[0-9]{1,2}-klass/));
    },
    classNumber() {
      return Number(this.$route.name.match(/[0-9]{1,2}/)?.[0]) || 8;
    },
    attestaciiPage() {
      return this.$route.name === 'online-school-attestacii-i-prikreplenie';
    },
    vecherneePage() {
      return this.$route.name === 'online-school-vechernee-obuchenie';
    },
    semeinoePage() {
      return this.$route.name === 'online-school-semeinoe-obuchenie';
    },

    title() {
      if (this.cenyPage) return 'Тарифы и форматы обучения в&nbsp;домашней&nbsp;школе';
      if (this.vecherneePage) return 'Тарифы и форматы вечернего обучения';
      if (this.semeinoePage) return 'Тарифы и форматы семейного обучения';
      return 'Выберите свой путь обучения';
    },

    packagesListVariable() {
      if (this.forCom) return 'schoolComPackages';
      // if (this.cenyPage || this.semeinoePage || this.vecherneePage) return 'schoolCenyPackages';
      // if (this.classPage) return 'schoolClassPackages';
      return 'schoolPackages';
    },
  },

  mounted() {
    if (this.classPage) {
      if (this.classNumber === 8 || this.classNumber === 9) {
        this.activeTab = 1;
      }
      if (this.classNumber === 10 || this.classNumber === 11) {
        this.activeTab = 2;
      }
    }
  },

  methods: {
    ...mapActions('modals', {
      toggleLead2Modal: 'toggleLead2',
      toggleLead1Modal: 'toggleLead1',
    }),
    // eslint-disable-next-line
    onTarrifsScroll: debounce(function (e) {
      if (!e.target || !this.isMobile) return;

      const { scrollLeft, offsetWidth, scrollWidth } = e.target;
      const slideWidth = e.target.scrollWidth / 3;

      if (scrollLeft < (slideWidth / 2)) {
        // slide 1
        e.target.scrollLeft = 0;
      } else if (scrollWidth < scrollLeft + offsetWidth + slideWidth / 2) {
        // slide 3
        e.target.scrollLeft = scrollWidth - offsetWidth;
      } else {
        // slide 2
        e.target.scrollLeft = (scrollWidth - offsetWidth) / 2;
      }
    }, 300),
  },
};
</script>

<style scoped lang="scss">

.school-way {
  overflow: hidden;
  position: relative;
  padding-bottom: 4px;
  padding-top: 180px;
  margin-top: -180px;

  .school-way__inner {
    margin-bottom: 40px;

    @include media-down(tablet) {
      margin-bottom: 20px;
    }
  }

  &__sticker {
    display: none;
    position: absolute;
    right: -16.2%;
    top: -5.2%;

    z-index: 3;

    @media (max-width: 1650px) {
      right: -120px;
    }

    @include media-down(desktop) {
      right: -20px;
      top: -7.5%;
    }

    @include media-down(laptop) {
      top: 4%;
    }

    @include media-down(tablet) {
      top: -7%;
      right: -25px;
    }

    &-image {
      max-width: 407px;
      transform: rotate(16.734deg);

      @include media-down(desktop) {
        max-width: 307px;
      }

      @include media-down(laptop) {
        max-width: 247px;
      }

      @include media-down(tablet) {
        max-width: 190px;
        transform: rotate(8.729deg);
      }
    }
  }

  h2 {
    position: relative;
    color: $black;
    font-size: 45px;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -1.35px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 60px;

    z-index: 4;

    @include media-down(laptop) {
      font-size: 40px;
    }

    @include media-down(tablet) {
      font-size: 35px;
      line-height: 100%;
      letter-spacing: -0.7px;
    }

    @include media-down(mobile) {
      max-width: 328px;
      margin: 2px auto 40px;
    }
  }

  .tabs {
    .tabs__head {
      display: flex;
      justify-content: center;

      margin: 0 auto 40px;

      min-width: 960px;
      width: calc(100% - 80px);

      border-bottom: 2px solid $black;

      @include media-down(laptop) {
        gap: 8px;

        margin: 0 -16px 16px;
        padding: 2px 16px;

        min-width: auto;
        width: calc(100vw - 17px);

        border: none;

        overflow: auto;
      }

      @include media-down(tablet) {
        width: 100vw;
        justify-content: flex-start;
      }
    }

    .tabs__btn {
      position: relative;
      z-index: 1;
      color: inherit;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      width: 320px;
      height: 120px;
      margin-left: -2px;

      background-color: $gray-bg;
      border: 2px dashed $black;
      border-bottom: 2px solid transparent;
      border-radius: 20px 20px 0 0;

      font-size: 35px;
      font-weight: 500;
      line-height: 28px;

      transition: 0.2s ease-out;

      @include media-down(laptop) {
        width: 120px;
        height: 88px;
        margin-left: 0;

        border-bottom: 2px dashed $black;
        border-radius: 16px;

        font-size: 18px;
        line-height: 18px;
      }

      &:not(.tabs__btn--fird):not(:hover):last-child {
        border-right: 2px dashed $black;
      }

      @media (hover: hover) {
        &:hover {
          z-index: 2;
          border: 2px solid transparent;

          box-shadow: 0 0 0 2px $black;
        }
      }

      &--active {
        z-index: 2;
        border: 2px solid transparent !important;
        box-shadow: 0 0 0 2px $black;
      }
    }
  }

  .school-way__tariffs {
    display: flex;
    gap: 30px;
    scroll-behavior: smooth;

    @include media-down(desktop) {
      gap: 25px;
    }

    @include media-down(laptop) {
      overflow: auto;
      padding-bottom: 5px;
    }

    @include media-down(tablet) {
      gap: 16px;
    }
  }

  .school-way__tariff {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 38px 23px 18px;

    position: relative;
    z-index: 2;

    border-radius: 15px;
    border: 2px dashed $black;
    background-color: $white-color;
    color: $black;

    overflow: hidden;

    &:hover {
      border-style: solid;
    }

    &:last-of-type {
      border-style: solid;
    }

    @include media-down(desktop) {
      padding-left: 16px;
      padding-right: 16px;
    }

    @include media-down(laptop) {
      width: 350px;
      padding: 40px 16px 21px;
      flex-shrink: 0;
      flex: none;
    }

    @include media-down(tablet) {
      width: 296px;
      border-style: solid;
    }

    .tariff__sticker {
      position: absolute;
      right: 0;
      top: 0;
    }

    .title-tertiary {
      max-width: 200px;
      margin-bottom: 10px;

      letter-spacing: -1.2px;
      text-transform: uppercase;

      @include media-down(laptop) {
        letter-spacing: -0.6px;
      }

      @include media-down(tablet) {
        margin-bottom: 8px;
        max-width: 150px;
      }
    }

    .tariff__head {
      min-height: 212px;
      margin-bottom: 40px;

      @include media-down(tablet) {
        margin-bottom: 32px;
      }

      @include media-down(desktop) {
        min-height: 250px;
      }

      @include media-down(desktop) {
        min-height: 240px;
      }
    }

    .tariff__description {
      margin-bottom: 20px;
      color: $black;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 20px;
      font-weight: 500;
      line-height: 130%;
      letter-spacing: -0.2px;

      @include media-down(tablet) {
        min-height: 108px;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: 0;
      }
    }

    .tariff__chips {
      display: flex;
      justify-content: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;
      gap: 4px 2px;
      min-height: 84px;
      width: calc(100% + 10px);
      margin-left: -5px;

      @include media-down(desktop) {
        min-height: 130px;
      }

      @include media-down(laptop) {
        width: calc(100% + 24px);
        margin-left: -8px;
      }

      @include media-down(tablet) {
        min-height: 110px;
        gap: 2px;
      }

      div {
        margin-bottom: 2px;
        border-radius: 50px;
        background-color: #E8E9EB;
        padding: 10px 15px 14px;
        font-size: 16px;
        font-weight: 700;
        line-height: 100%;
        color: #242424;

        @include media-down(tablet) {
          padding: 7px 11px 11px;
          font-size: 14px;
        }
      }
    }

    .tariff_price {
      margin-bottom: 40px;
      font-size: 20px;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: -0.8px;

      @include media-down(laptop) {
        font-size: 18px;
        letter-spacing: 0;
        font-weight: 600;
        line-height: 120%;
      }

      @include media-down(tablet) {
        margin-bottom: 32px;
        font-size: 16px;
      }

      .tariff_price__from {
        display: block;
        margin-bottom: 20px;
      }

      .tariff_price__price {
        font-size: 65px;
        letter-spacing: -1.3px;
        font-weight: 700;

        @include media-down(desktop) {
          font-size: 62px;
        }

        @include media-down(laptop) {
          font-size: 60px;
        }

        @include media-down(tablet) {
          font-size: 50px;
        }
      }

      .tariff_price__unit {
        position: relative;
        top: -8px;
      }

      .tariff_price__installment {
        display: block;
        //margin-top: 5px;
        font-size: 20px;
        font-weight: 500;
        line-height: 90%;
        letter-spacing: -0.4px;
        color: $grey2;

        @include media-down(tablet) {
          font-size: 16px;
          letter-spacing: -0.32px;
        }
      }
    }

    .tariff_price--discount {
      line-height: unset;

      .tariff_price__old {
        display: block;
        margin-bottom: 3px;
        color: $grey2;
        font-variant-numeric: lining-nums proportional-nums;
        font-feature-settings: 'case' on;
        font-size: 20px;
        font-weight: 500;
        line-height: 90%;
        letter-spacing: -0.4px;

        @include media-down(tablet) {
          margin-bottom: 2px;
          font-size: 16px;
          letter-spacing: -0.32px;
        }

        span {
          font-weight: 600;
        }

        b {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 90%;
          letter-spacing: -0.02em;
          font-feature-settings: 'pnum' on, 'lnum' on, 'case' on;
          color: #FA5019;
          padding-left: 6px;
        }
      }

      .tariff_price__installment {
        display: block;
        color: $grey2;
        font-variant-numeric: lining-nums proportional-nums;
        font-feature-settings: 'case' on;
        font-size: 20px;
        font-weight: 500;
        line-height: 90%;
        letter-spacing: -0.4px;

        @include media-down(tablet) {
          margin-top: 2px;
          font-size: 16px;
          letter-spacing: -0.32px;
        }
      }
    }

    ul {
      margin-bottom: 40px;

      .text-primary {
        font-variant-numeric: lining-nums proportional-nums;
        letter-spacing: -0.2px;
        padding-left: 11px;
        position: relative;

        @include media-down(tablet) {
          padding-left: 14px;
          font-size: 14px;
        }

        &:not(:last-child) {
          margin-bottom: 15px;

          @include media-down(tablet) {
            margin-bottom: 8px;
          }
        }

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 6px;
          height: 100%;

          background-image: url("/v2/school/way/li-star.svg");
          background-repeat: no-repeat;
          background-size: contain;

          @include media-down(tablet) {
            top: -2px;
          }
        }
      }
    }

    button {
      width: 100%;
      padding: 15px 38px 21px;
      margin-top: auto;
      display: block;

      border-radius: 500px;
      border: 2px solid $black;
      background-color: var(--clr);

      color: $black;
      font-size: 30px;
      font-weight: 700;
      line-height: 100%;

      transition: 200ms ease-in;

      @include media-down(tablet) {
        border-style: solid;
        transform: translate(-2px, -4px) !important;
        box-shadow: 2px 4px 0 0 $black !important;
        //width: fit-content;
        font-size: 25px;
      }

      &:hover {
        transform: translate(-4px, -4px);
        box-shadow: 4px 4px 0 0 $black;
      }
    }

    button.tariff__btn-filled {
      border-style: solid;
      background-color: var(--clr);
    }
  }

  .eac-items__container {
    margin-bottom: 100px;
  }

  .school-way__education {
    margin-bottom: 100px;
  }

  .school-way__img-star {
    position: absolute;
    right: calc((100% - min(1320px, calc(100% - 16px))) / 2 - 47px);
    bottom: 1090px;

    @include media-down(tablet) {
      display: none;
    }
  }

  .school-way__img-circle-star {
    position: absolute;
    left: calc((100% - min(1320px, calc(100% - 16px))) / 2 - 70px);
    top: 1140px;

    @include media-down(tablet) {
      display: none;
    }
  }
}

::v-deep .school-way-banners {
  margin-top: 30px;
  margin-bottom: 180px;

  @include media-down(laptop) {
    margin-bottom: 120px;
  }

  @include media-down(tablet) {
    margin-bottom: 80px;
  }
}

</style>
